import React from "react";
import "./logo.css"

function Logo() {
  return (
    <div className="logo">
      <h5 className="logo-h4">CHRIS YORKE LANDSCAPING</h5>
    </div>
  );
}

export default Logo;