import React from "react";
import "../pages/pages.css";
import Services from "../components/services/services";





// function that creates a body for the homepage including information about what the app is about
function Contact() {
  return (
    <section className="body-section">
      <Services/>
     
     
    </section>
  );
}

export default Contact;